<template>
  <div class="container">
    <div class="main">
      <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
        <div v-for="item in list" :key="item.book_id" class="list-item" @click="onNavigator({path:'/student/submit-comment?book_id='+item.book_id})">
          <div class="list-item-media">
            <van-image class="list-item-media-img" :src="item.course_teacher.teacher_avatar" />
          </div>
          <div class="list-item-inner">
            <div class="list-item-title">{{ item.course_teacher.teacher_name }}</div>
            <div class="list-item-class">
              <div class="class">
                <div class="class-media">
                  <van-image class="class-media-img" :src="item.course_cover" />
                </div>
                <div class="class-inner">
                  <div class="class-title van-ellipsis">{{ item.class_name }}</div>
                  <div class="class-txt">上课教室：{{ item.room_name }}</div>
                  <div class="class-txt">上课时间：{{ item.class_date }} {{ item.class_start }}</div>
                </div>
              </div>
            </div>
            <div class="list-item-bottom">
              <div v-if="item.comments.length" class="comment">
                <div v-for="comment in item.comments" :key="comment.commnet_id" class="comment-item van-hairline--bottom">
                  <div class="comment-item-media">
                    <van-image class="comment-item-media-img" :src="comment.commnet_avatar" />
                  </div>
                  <div class="comment-item-info">
                    <div class="comment-item-name">{{ comment.commnet_name }}</div>
                    <div class="comment-item-content">{{ comment.commnet_content }}</div>
                    <div class="comment-item-time">{{ comment.comment_at }}</div>
                  </div>
                  <div class="comment-item-right">
                    <div class="comment-item-btn"><van-icon class-prefix="icon" class="mr5" size="12" color="#ccc" name="CounselOutline" />{{ item.comments.length }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'

export default {
  name: 'StudentCommentClass',
  mixins: [PageMixin],
  data() {
    return {
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1
    }
  },
  created() {
    // this.onListLoad()
  },
  methods: {
    onListLoad() {
      this.onPage()
    },
    onPage() {
      this.$api.schedule_feedback({ 'per-page': this.pageSize, page: this.pageCurrent }).then(res => {
        this.listLoading = false
        this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
        this.listFinished = this.list.length >= res._meta.totalCount
        this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
        this.pageCurrent += 1
      })
    }
  }
}
</script>
<style lang="less" scoped>

    .container{
      padding: 10px 0;
    }
    .list{

    }
    .list-item{
      box-sizing: border-box;
      width: 100%;
      padding: 10px 15px;
      display: flex;
      justify-content: flex-start;
      background: #fff;
      &-media{
        margin-right: 10px;
        &-img{
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
        }
      }
      &-title{
        font-size: 14px;
        color: #222;
        margin-bottom: 6px;
      }
      &-inner{
        flex: 1;
        width: 100%;
      }
    }
    .class{
      background: #fafafa;
      padding: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      &-media{
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }
      &-inner{
        flex: 1;
        height: 50px;
      }
      &-title{
        font-size: 14px;
        font-weight: bold;
        color: #333;
      }
      &-txt{
        font-size: 12px;
        color: #666;
      }
    }

    .comment{

    }
    .comment-item{
      padding: 5px 10px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      &-right{
        display: flex;
        justify-content: flex-end;

      }
      &-btn{
        min-width: 26px;
        border-radius: 15px;
        padding: 2px 5px;
        font-size: 12px;
        color: #999;
        border: 1px solid #ddd;
      }
      &-media{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
      }
      &-info{
        flex: 1;
      }
      &-name{
        font-size: 12px;
        color: #333;
        margin-bottom: 6px;
      }
      &-content{
        font-size: 14px;
        color: #333;
        margin-bottom: 6px;
      }
      &-time{
        font-size: 12px;
        color: #666;
      }
    }
</style>

